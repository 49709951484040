<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>Consulta de Historicos</h1>
    <form
      v-show="items.length === 0"
      class="w-50 lg:w-25 mx-auto my-4"
      @submit.prevent="confirm()"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                :hidden="f.hidden"
                @input="getContract(data[f.key])"
              />
            </div>
            <b-form-input
              v-else
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
            />
          </div>
          <div v-if="f.type === 'select-services'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="optionsServices"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            v-model="data[f.key]"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <b-button
          variant="primary mt-3"
          @click="confirm()"
        >
          Confirmar
        </b-button>
      </div>
    </form>
    <b-card v-if="items.length > 0">
      <b-button
        variant="primary my-3"
        @click="items = []"
      >
        Consultar otro contrato
      </b-button>
      <ArqTable
        responsive="sm"
        hover
        :rows="items"
        :head="facturasTh"
        class="w-75 mx-auto"
      >
        <template
          #Referencia_Pago="{ data }"
          style="background: blue; text-align: center"
        >
          <div class="text-right text-primary">
            {{ data.value }}
          </div>
        </template>
        <template
          #Valor_Pagado="{ data }"
          style="background: blue; text-align: center"
        >
          <div class="text-right text-primary">
            {{ data.value | toCurrency }}
          </div>
        </template>
      </ArqTable>
    </b-card>
    <b-modal
      id="modal-confirm-process"
      :title="response.title"
      ok-only
    >
      <div>
        <b-alert
          :variant="response.variant"
          show
        >
          {{ response.message }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentPage: 0,
      items: [],
      pageCount: 0,
      data: {},
      pdfBase64: '',
      process: 'descargar-duplicados',
      title: 'Descarga de Duplicados',
      procedure: '/history',
      method: 'get',
      facturas: [],
      facturasTh: ['Fecha Pago', 'Entidad', 'Referencia Pago', 'Valor Pagado'],
      response: {},
      form: [
        {
          label: '',
          key: 'identification',
          type: 'number',
          required: true,
          hidden: true,
        },
        {
          label: 'Contrato',
          key: 'idcontrato',
          type: 'select-contract',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
    }
  },
  mounted() {
    this.data.identification = this.$store.state.client.identification
  },
  computed: {
    ...mapState('client', ['contratos']),
  },
  methods: {
    descargarFactura(idCuenta, i) {
      this.facturas = this.facturas.map(el => {
        const newEl = el
        newEl.variant = false
        return newEl
      })
      this.facturas[i].variant = 'primary'
      const fd = { idCuenta }
      this.sendGetProcedure('/descargaDuplicado', fd).then(res => {
        //  console.log('res', res)
        this.facturas = res.data.list.map(el => [
          el.fechaPago,
          el.nombreEntidad,
          el.referenciaPago,
          el.valorPagado,
        ])
      })
    },
    confirm() {
      this.sendGetProcedure(this.procedure, this.data).then(res => {
        //  console.log('res', res)
        if (res.data.list.length === 0) {
          this.response = {
            title: 'Información',
            message: 'No se encontro registro de historico para este contrato.',
            variant: 'warning',
          }
          this.$bvModal.show('modal-confirm-process')
        } else {
          this.items = res.data.list.map(el => [
            el.fechaPago,
            el.nombreEntidad,
            el.referenciaPago,
            el.valorPagado,
          ])
        }
      })
    },
    async sendGetProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .get(`/client/payment${procedure}`, { params: formData })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>

<style></style>
